import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React, { Component } from "react";
import type { TranslationKey } from "src/types/translationKey";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Translate from "src/ui/components/Translate/Translate";

const TableItem = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1em;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--light-shadow);
  max-width: 100%;

  td {
    padding: 0.5em 1em;
    text-align: left;
  }

  tr {
    &:first-of-type {
      td {
        padding-top: 1em;
      }
    }

    &:last-of-type {
      td {
        padding-bottom: 1em;
      }
    }
  }

  .data-row-title {
    opacity: 0.6;
  }
`;

const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0 0.3em;

  &:first-of-type {
    padding-top: 0;
  }

  button {
    background: none;
    border: none;
    opacity: 0.6;
    position: relative;
    z-index: 10;
    padding: 1.4em;
    margin: -1.4em;
    font-size: 1rem;

    &:focus {
      outline: 2px solid initial;
    }
  }

  h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
  }
`;

interface Action {
  label?: TranslationKey;
  onAction: () => void;
  hidden?: boolean;
}

const Table: FC<{
  title: ReactNode;
  action?: Action;
  children: React.ReactNode;
}> = (props) => {
  const { title, action } = props;
  const titleId =
    typeof title === "string" ? title.replace(/\s/g, "-").toLowerCase() : "";

  return (
    <>
      <TableHead aria-labelledby={titleId}>
        <h6 id={titleId}>{props.title}</h6>
        {props.action && !props.action.hidden && (
          <button onClick={props.action.onAction}>
            {action?.label ? (
              <Translate msg={action.label}></Translate>
            ) : (
              <Translate msg="button.edit" />
            )}
          </button>
        )}
      </TableHead>
      <TableItem>
        <tbody>{props.children}</tbody>
      </TableItem>
    </>
  );
};

const Section: FC<{
  title: ReactNode;
  action?: Action;
  children: ReactNode;
}> = (props) => {
  const { title } = props;
  const titleId =
    typeof title === "string" ? title.replace(/\s/g, "-").toLowerCase() : "";

  return (
    <>
      <TableHead aria-labelledby={titleId}>
        <h6 id={titleId}>{props.title}</h6>
        {props.action && !props.action.hidden && (
          <button onClick={props.action.onAction}>
            {props.action.label ? (
              <Translate msg={props.action.label}></Translate>
            ) : (
              <Translate msg="button.edit"></Translate>
            )}
          </button>
        )}
      </TableHead>
      <TableItem as="div">{props.children}</TableItem>
    </>
  );
};

const RowItem = styled.tr`
  padding: 1em 0;
  td {
    &[data-ellipsis="true"] span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: min(calc(100vw - 6em), 400px);
    }
  }
`;

const Row: FC<{
  title?: string;
  data?: ReactNode | boolean;
  ellipsis?: boolean;
}> = (props) => {
  return (
    <RowItem>
      {props.title && <td className="data-row-title">{props.title}</td>}
      <td rowSpan={props.title ? 1 : 2} data-ellipsis={props.ellipsis}>
        <AsyncContent width={"100%"} check={[props.data]} height={"1rem"}>
          <span>{props.data}</span>
        </AsyncContent>
      </td>
    </RowItem>
  );
};

class Data extends Component {
  static Table = Table;
  static Row = Row;

  static Section = Section;

  render(): React.JSX.Element {
    return <></>;
  }
}

export default Data;
